import {isMobileDevice} from "../../layout/device";
import * as $$ from "jquery-selector-cache";
import {
    type ProductDetailClickedData,
    type ProductDetailShownData,
    type ResultClickedData,
    type SearchRequestedData,
    type SearchServedData,
    type TrackingDataModel
} from "../../typings/tracking/tracking";
import {v4 as uuidv4} from "uuid";
import * as Cookies from "js-cookie";
import {addsCookie, getCookie} from "../Cookie";
import {getUrlVar} from "orxapi.tools.url";
import {urlParametersConfig} from "../../config/form";

function generateRandomId() {
    // Transform the UUID , remove -
    const formattedIdentifier = uuidv4().replace(/-/g, "");
    return formattedIdentifier;
}

function getSearchId(): string {
    let searchId = "";
    const referrer = document.referrer;
    const domain = document.domain;
    //if not from extern landing, not generate the new searchId, get from cookie
    if (referrer && referrer.includes(domain)) {
        searchId = getCookie("trackingSearchId") || "";
    }
    return searchId;
}

function sendSearchRequestTrackingLanding() {
    const searchSubmit = getUrlVar()[urlParametersConfig.searchSubmit];
    if(!searchSubmit || searchSubmit !== "true"){
        const formData = new FormData($$("#filter-panel-content")[0]);
        sendSearchRequestTracking(formData, getSearchId());
    }
}

function sendSearchRequestTracking(formData: FormData, trackingSearchId?: string) {
    const eventTrackingUrl = getEventTrackingUrl();
    //if not has eventTrackingUrl, the tracking is disabled
    if (eventTrackingUrl) {
        const destination = formData.get("ref_c.de") as string || formData.get("s_c.de") as string;
        const minDate = formData.get("s_midpda") as string;
        const maxDate = formData.get("s_mxdpda") as string;
        const monthYear = formData.get("s_dmy") as string;
        const day = formData.get("s_dd") as string;
        const minMan = formData.get("s_minMan") as string || "";
        const min = parseInt(minMan.split(",")[0] ?? "");
        const max = parseInt(minMan.split(",")[1] ?? "");
        const departureDate = buildDepartureDate(monthYear, day);
        const flexibleDate = parseInt(formData.get("s_aj") as string);
        const departureCity = formData.get("s_dpci") as string;
        const channel = getUrlVar()[urlParametersConfig.canal];
        if (channel) {
            formData.set(urlParametersConfig.canal, channel);
        }
        const eventType = "SEARCH_REQUESTED";
        const searchId = trackingSearchId || generateRandomId();
        const data: SearchRequestedData = {
            eventType: eventType,
            searchId: searchId,
            destination: destination,
            lengthOfStay: {
                min: isNaN(min) ? null : min,
                max: isNaN(max) ? null : max,
            },
            departureDate: {
                from: minDate || departureDate,
                to: maxDate || departureDate,
            },
            departure: departureCity,
            returnDate: {
                from: "",
                to: "",
            },
            userUid: "",
            flexibleDate: isNaN(flexibleDate) ? 0 : flexibleDate,
            completeUrl: `https://${window.location.hostname}/serp?${formDataToQueryString(formData)}`
        };
        sendEventTracking(eventTrackingUrl, eventType, data);
        //We have to preserve the searchid generated until a new search has been requested by the customer
        addsCookie("trackingSearchId", searchId);
    }
}

function sendSearchServedTracking() {
    const eventTrackingUrl = getEventTrackingUrl();
    if (eventTrackingUrl) {
        const resultSort = document.querySelector(".results-sort-label");
        const resultNumber = parseInt(resultSort ? resultSort.getAttribute("data-result-number") : "", 10);
        const eventType = "SEARCH_SERVED";
        const searchId = getCookie("trackingSearchId");
        const data: SearchServedData = {
            eventType: eventType,
            searchId: searchId,
            responseCode: "OK",
            responseDetail: "ready",
            numberOfResults: isNaN(resultNumber) ? 0 : resultNumber
        };
        sendEventTracking(eventTrackingUrl, eventType, data);
    }
}

function sendResultClickedTracking(code: string, price: string) {
    const eventTrackingUrl = getEventTrackingUrl();
    if (eventTrackingUrl) {
        const eventType = "RESULT_CLICKED";
        const clickId = generateRandomId();
        const searchId = getCookie("trackingSearchId");
        const data: ResultClickedData = {
            eventType: eventType,
            searchId: searchId,
            clickId: clickId,
            productId: code,
            currency: "EUR",
            totalPrice: price
        };
        sendEventTracking(eventTrackingUrl, eventType, data);
        addsCookie("trackingClickId", clickId);
    }
}

function sendProductDetailShownTracking(code: string, price: string) {
    const eventTrackingUrl = getEventTrackingUrl();
    if (eventTrackingUrl) {
        const eventType = "PRODUCT_DETAIL_SHOWN";
        let clickId = "";
        let searchId = "";
        const referrer = document.referrer;
        if (referrer && referrer.includes("/serp")) {
            //click from serp
            clickId = getCookie("trackingClickId");
        }
        searchId = getCookie("trackingSearchId");
        const queryParams = new URLSearchParams(window.location.hash);
        const data: ProductDetailShownData = {
            eventType: eventType,
            searchId: searchId,
            clickId: clickId,
            productId: code,
            totalPrice: price,
            clickoutId: queryParams.get("trackingId") || queryParams.get("hdpSearchId") || ""
        };
        sendEventTracking(eventTrackingUrl, eventType, data);
    }
}

function sendProductDetailClickedTracking(bookingForm: JQuery<HTMLElement>) {
    const eventTrackingUrl = getEventTrackingUrl();
    if (eventTrackingUrl) {
        const eventType = "PRODUCT_DETAIL_CLICKED";
        const searchId = getCookie("trackingSearchId");
        const avail = (bookingForm.find("input[name='disponibility']").val() as string).split("-");
        const travellers = bookingForm.serializeArray().filter(item => item.name.startsWith("traveller[") && item.name.endsWith("].type"));
        let adultsNumber = 0;
        let childrenNumber = 0;
        travellers.forEach(traveller => {
            if (traveller.value === "A") {
                adultsNumber += 1;
            } else if (traveller.value === "C") {
                childrenNumber += 1;
            }
        });
        const data: ProductDetailClickedData = {
            eventType: eventType,
            searchId: searchId,
            departure: bookingForm.find("input[name='depCityCode']").val() as string,
            departureDate: `${avail[2]}-${avail[1]}-${avail[0]}`,
            lengthOfStay: Number(avail[3]),
            adultQty: adultsNumber,
            infantQty: childrenNumber,
        };
        sendEventTracking(eventTrackingUrl, eventType, data);
    }
}

function sendEventTracking(url: string, eventType: string, payloadData: SearchRequestedData | SearchServedData | ResultClickedData | ProductDetailShownData | ProductDetailClickedData) {
    const headers = generateHeaders();
    void $.ajax(url, {
        method: "POST",
        data: JSON.stringify(buildEventTrackingData(eventType, payloadData)),
        contentType: "application/json",
        dataType: "json",
        headers: headers
    });
}

function buildEventTrackingData(eventType: string, payloadData: SearchRequestedData | SearchServedData | ResultClickedData | ProductDetailShownData | ProductDetailClickedData): TrackingDataModel {
    const device = isMobileDevice() ? "MOBILE" : "DESKTOP";
    payloadData["device"] = device;
    let isLoggedIn = false;
    let userUid = Cookies.get("__vuc");
    if (userUid && userUid !== "") {
        userUid = userUid.replace(/^V05_(.*?)_$/, "$1");
        isLoggedIn = true;
    }
    payloadData["userUid"] = userUid;
    payloadData["isLoggedIn"] = isLoggedIn;
    const sendDate = getSendDate();
    return {
        "sentDateTime": sendDate,
        "events": [
            {
                "dateTime": sendDate,
                "name": eventType,
                "version": "2.0",
                "payload": payloadData
            }
        ]
    };
}

function getSendDate() {
    const currentDateTime = new Date();
    return currentDateTime.toISOString();
}

function getEventTrackingUrl(): string {
    const eventTrackingUrl = document.querySelector("#eventTrackingUrl");
    return eventTrackingUrl ? eventTrackingUrl.getAttribute("data-url") : "";
}

/**
 * generate a random 7-digit numeric ID padded with zeros
 */
function generateRandomSpanId() {
    const randomId = Math.floor(Math.random() * 10000000);
    return randomId.toString().padStart(7, "0");
}

function getHostIdentifier() {
    return "orchestra-platform";
}

/**
 * generate the X-BF-tracing-traceId (UUID type 4)
 */
function generateTraceId() {
    return uuidv4();
}

function generateHeaders() {
    const traceId = generateTraceId();
    const spanId = generateRandomSpanId();
    const parentSpanId = "0000000";
    const hostId = getHostIdentifier();
    const methodName = "v1/events";
    const appName = "voyage-lastminute";

    return {
        "X-BF-tracing-traceId": traceId,
        "X-BF-tracing-spanId": spanId,
        "X-BF-tracing-parent-spanId": parentSpanId,
        "X-BF-tracing-hostId": hostId,
        "X-BF-tracing-methodName": methodName,
        "X-BF-tracing-appName": appName,
    };
}

function buildDepartureDate(monthYear: string, day: string): string {
    if (!monthYear || !monthYear.includes("/") || !day || !day.length) {
        return "";
    }
    const monthYearData = monthYear.split("/");
    return monthYearData[1] + "-" + monthYearData[0] + "-" + day;
}

function formDataToQueryString(formData: FormData) {
    const params = new URLSearchParams();
    formData.forEach((value, key) => {
        params.append(key, value as string);
    });
    return params.toString();
}

export {
    generateRandomId,
    sendEventTracking,
    getEventTrackingUrl,
    buildDepartureDate,
    formDataToQueryString,
    sendSearchRequestTracking,
    sendSearchServedTracking,
    sendResultClickedTracking,
    sendProductDetailShownTracking,
    sendProductDetailClickedTracking,
    sendSearchRequestTrackingLanding,
};